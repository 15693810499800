<template>
  <div class="container" ref="wrapper">
    <van-sticky>
      <van-nav-bar
        :title="articles.name"
        left-arrow
        @click-left="$router.back()"
      >
        <template #right>
          <div @click="toHome()">
            <van-icon name="wap-home-o" size="18" />
            <div style="float: right; margin-left: 5px; font-size: 15px">
              首页
            </div>
          </div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <van-row style="margin: 5px">
      <van-col span="12">
        <div class="des">零售价格: {{ articles.cash }}</div>
      </van-col>
      <van-col span="12" class="des">兑换{{blockName}}: {{ articles.score }}</van-col>
    </van-row>
    <van-row style="margin: 5px">
      <van-col span="12">
        <div class="des">库存: {{ articles.stockAmount }}</div>
      </van-col>
      <van-col span="12">
        <div class="des">
          限购:
          <span v-if="articles.maxAmount > 0">{{ articles.maxAmount }}</span>
          <span v-if="articles.maxAmount == 0">不限</span>
        </div>
      </van-col>
    </van-row>
    <van-row>
      <van-col span="24">
        <div class="acontent" v-html="articles.detailsHtml"></div>
      </van-col>
    </van-row>
    <div style="position: fixed; bottom: 0; left: 0; z-index: 100; width: 100%">
      <div style="background: #fff; line-height: 35px; font-size: 15px">
        <div>
          <div style="float: left; margin-left: 10px">兑换数量：</div>
          <van-stepper
            v-model="amount"
            theme="round"
            button-size="22"
            disable-input
            :max="articles.maxAmount > 0 ? articles.maxAmount : 1000"
          />
        </div>
      </div>

      <van-submit-bar
        :price="articles.score * 100"
        :decimal-length="0"
        button-text="兑换"
        @submit="exchange"
        :tip="msg"
        :disabled="num"
        :label="'所需'+blockName+':'"
        currency=""
      />
    </div>
  </div>
</template>
<script>
import { getEshopDetail, getMyScore, userExchange } from "@/api/shop"; //调用
import { Toast } from "vant";

export default {
  name: "eshop-detail",
  data() {
    return {
      info: "",
      blockName:"积分",
      articles: { score: 0 },
      num: false,
      msg: "",
      amount: 1,
      page: {
        title: "",

        userScore: 0,
        success: true,
      },
    };
  },
  activated() {
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.onLoad();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path == "/eshop/detail") {
        if (to.query != from.query) {
          //this.loading = true;
          this.onLoad();
        }
      }
    },
  },
  updated() {
    this.addpreview();
  },
  methods: {
    toHome() {
      this.$router.replace({ path: "/" });
    },
    async onLoad() {
      this.num = false;
      this.msg = "";
      this.amount = 1;
      let para = this.$route.query.para;
      let aresult = await getEshopDetail(para);
      this.articles = aresult.data.data;
      if(this.articles.blockId==0){
            this.blockName='积分';
      }
      else if(this.articles.blockId==1){
      this.blockName='能量值';
     }

      let myScore = await getMyScore(this.articles.blockId);
      this.userScore = myScore.data.data.canUseScore;
      this.canPurchase();
      console.log("ok");
    },
    addpreview() {
      var imgs = document.getElementsByTagName("img");

      imgs.forEach((element) => {
        element.setAttribute("preview", this.articles.id);
      });
      this.$previewRefresh();
    },

    canPurchase() {
      if (this.articles.status != 0) {
        this.num = true;
        this.msg = "此商品已经下架";
      }
      else if (this.articles.stockAmount == 0) {
        this.num = true;
        this.msg = "此商品已无库存";
      }
       else if (this.userScore < this.articles.score) {
        this.num = true;
        this.msg = "您的"+this.blockName+"不够兑换";
      }
    },

    async exchange() {
      await this.$dialog.confirm({
        title: "提示",
        message: "确定要兑换吗",
      });

      let result = await userExchange({
        productId: this.articles.id,
        amount: this.amount,
      });
      if (result.data.success) {
        Toast("兑换成功");
      } else {
        Toast(result.data.errorMessage);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding-bottom: 120px;
  /deep/.van-submit-bar {
    position: unset !important;
  }
}

.des {
  font-size: 15px;
  font-family: Tahoma, Geneva, sans-serif;
}
.acontent {
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
</style>

