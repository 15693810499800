import request from '@/utils/request'

// /api/Product/GetName
export function getProductName(id) {
  return request({
    url: `/api/Product/GetName?id=${id}`
  })
}

export function getEshopProductList(params) {
  return request({
    url: '/api/ScoreProduct/List',
    data: params,
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function getEshopDetail(id) {
  return request({
    url: `/api/ScoreProduct?id=${id}`
  })
}


export function getMyScore(blockId) {
  return request({
    url: `/api/Score/GetMyScore?blockId=${blockId}`
  })
}

export function userExchange(product) {
  return request({
    method: 'post',
    data: product,
    url: "/api/ScoreProduct/exchange",
    header: {
      'Content-Type': 'application/json;charset=utf-8'
    }
  })
}

export function getEshopProductClass(blockId) {

  var pid=200;
  if(blockId==1){
    pid=201
  }
  let para = {
    current: 1,
    pageSize: 20,
    filterData: {
      status: 0,
      orderIndex: 0,
      parentID: pid
    },
  }
  return request({
    url: '/api/Article/typeList',
    data: para,
    method:
      'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}